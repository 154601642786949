import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import { defaultLocale } from '../i18n/helpers';
import Layout from '../templates/Layout/Layout';

export default () => {
  useEffect(() => {
    navigate(`/${defaultLocale()}/`);
  });

  return <Layout />;
};
